import React, { useState } from 'react';
import './CustomOptionButton.css';

function LifeChallengeQuestions({ triggerNextStep, triggerData }) {
  const [clicked, setClicked] = useState(false);
  const [clickedMessage, setClickedMessage] = useState(""); // New state for clicked message
  const { yesOption } = triggerData;

  const questions = ["상관격이 뭐야", "어떻게 하면 잘 살 수 있을까", "다음으로 넘어갈게"]; // Array of questions

  // #TODO: 자유질문의 경우는 별도로 버튼 클릭이 있어야 한다.
  const handleClick = (message) => {
    setClicked(true);
    setClickedMessage(message); // Set the clicked message

    setTimeout(() => {

      triggerNextStep({
        trigger: 'user-input-processor',
        value: message
    });

    }, 500); // 0.5초 후에 triggerNextStep 호출
  };

  return (
    <div className={clicked ? "outer-container clicked" : "outer-container"}>
      {clicked ? (
        <div className="user-message-container">
          <div className="user-message">
            {clickedMessage}🙌
          </div>
        </div>
      ) : (
        <div className="button-container">
          {questions.map((question, index) => (
            <button
              key={index}
              className="styled-button"
              onClick={() => handleClick(question)}
            >
              {question}🙌 <span className="arrow">←</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
export default LifeChallengeQuestions;