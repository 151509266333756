import React, { useState, useEffect, useMemo, useCallback, useRef} from 'react';
import ChatBot from 'react-simple-chatbot';
// import { useThread } from '../ThreadContext';
import '../App.css';
import botAvatar from '../male_avatar_bot_profile-min.png'
import { ThemeProvider } from "styled-components"
import FortuneList from './contents/FortuneList';
import CustomOptionButtons from './contents/CustomOptionButton';
import LifeChallengeQuestions from './contents/LifeChallengeQuestions';
import ReactDOM from 'react-dom';
//TODO: 시간 setState해서 세션이 끝나기 전까지 유지해 줄 수 있도록 한다.

const csrfToken = window.csrfToken;

// const ohangColors = {
//   "화": "#FF2C4C", // Light Salmon (연한 Red)
//   "수": "#71A8FF", // Light Sky Blue (연한 Blue)
//   "목": "#00DE5D", // Light Green (연한 Green)
//   "금": "#FFE448", // Moccasin (연한 Gold)
//   "토": "#886F68", // Tan (연한 Brown)
// };


const ohangData = {
  "목": { color: "#00DE5D", symbol: "🌳" },
  "화": { color: "#FF2C4C", symbol: "🔥" },
  "토": { color: "#886F68", symbol: "🏔️" },
  "금": { color: "#FFE448", symbol: "🪙" },
  "수": { color: "#71A8FF", symbol: "💧" }
};


// TODO:
// 3. 봇 채팅창 색깔 테스트 - 사용자와 봇의 채팅창 UI 변경 시키기
// 4. 사주팔자의 의의를 설명해주기
// 5. 물상 이미지 넣기
// 6. 페이먼트 넣기
// 7. 소셜 로그인 넣기
// 8. 기본 DB 구축
// 9. 연결 부드럽게해주기
// 10. 애정운의 경우, 일간이나 격으로 궁합 잘 맞는 혹은 잘 맞지 않는 일주나 격 알려주면 어떨지?
// 11. 다음의 경우, 버튼으로 대체하는게 더 나을수도 있다는 생각이 들긴 한다..
// 12. 영어정보

function SajuPage() {

  const chatbotRef = useRef(null);

  const scrollToBottom = () => {
    if (chatbotRef.current) {
      // If chatbotRef.current is a React component instance, use ReactDOM.findDOMNode
      const chatbotNode = ReactDOM.findDOMNode(chatbotRef.current);
      if (chatbotNode) {
        // TODO: ChatBot.jsx:118 [Deprecation] Listener added for a 'DOMNodeInserted' mutation event. Support for this event type has been removed, and this event will no longer be fired.
        // Fix : Deprecation 에러 해결
  
        const scrollContainer = chatbotNode.querySelector('.rsc-content');
        if (scrollContainer) {
          // Use requestAnimationFrame to ensure the scroll action is performed at the right time
          requestAnimationFrame(() => {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          });
        } else {
          console.log('scrollContainer (.rsc-content) not found');
        }
      } else {
        console.log('chatbotNode is null');
      }
    } else {
      console.log('chatbotRef.current is null');
    }
  };

  // FOCUS: 추가 파라미터를 저장하는 리프
  const additionalParamsRef = useRef({
    action: 'initial-step',
    output_format: '',
    api_topic_id_key: '',
    current_display: '',
    remaining_questions: 3,
    display_order: ['life_challenge', 'money_luck', 'love_luck', 'career_luck', 'study_luck'],
    display_order_kor_str: ['나를 돌아보는 시간', '재물운', '애정운', '직장|명예운', '학업|계약운']
  });

  const updateAdditionalParams = async (newParams, calledFrom) => {
    return new Promise((resolve) => {
      additionalParamsRef.current = { ...additionalParamsRef.current, ...newParams };
      console.log("##Additional Param Updated:", additionalParamsRef.current);
      console.log("## CALLED BY:", calledFrom);
      
      // setTimeout을 사용하여 다음 이벤트 루프까지 기다립니다.
      // 이는 상태 업데이트가 반영될 시간을 줍니다.
      setTimeout(() => {
        resolve(additionalParamsRef.current);
      }, 0);
    });
  };

  const [assistantId, setAssistantId] = useState('draw_base_saju');
  const [threadId, setThreadId] = useState('');
  const [threadMap, setThreadMap] = useState(new Map());


  const [birthday, setBirthday] = useState({
      year: 1990,
      month: 12,
      day: 12,
      hour: 12,
      minute: 12
  });

  const birthdayRef = useRef(birthday)

  useEffect(()=>{
    birthdayRef.current = birthday
    console.log("Birthday REF Set!", birthdayRef.current )
  },[birthday])

  // Refs to store the latest threadId and assistantId
  const threadIdRef = useRef(threadId);
  const assistantIdRef = useRef(assistantId);

  useEffect(() => {
    threadIdRef.current = threadId;
  }, [threadId]);

  useEffect(() => {
    assistantIdRef.current = assistantId;
  }, [assistantId]);

  const handleAssistantIdChange = (newAssistantId) => {
    setThreadMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(assistantId, threadIdRef.current); // Use ref to ensure the latest threadId
      return newMap;
    });

    setAssistantId(newAssistantId);
    setThreadId(threadMap.get(newAssistantId) || '');
  };

  const handleThreadIdChange = (newThreadId) => {
    setThreadId(newThreadId);
    setThreadMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(assistantIdRef.current, newThreadId); // Use ref to ensure the latest assistantId
      return newMap;
    });
  };

  const changeAssistantAndThreadId = async (newAssistantId, newThreadId) => {
    // 상태 업데이트는 비동기이기 때문에 그 이후 작업을 처리하기 위해 Promise를 사용
    await new Promise((resolve) => {
      setThreadMap((prevMap) => {
        const newMap = new Map(prevMap);
        newMap.set(assistantId, threadIdRef.current);
        return newMap;
      });

      // 상태 업데이트 후 resolve 호출
      setAssistantId(newAssistantId);
      handleThreadIdChange(newThreadId);
      resolve();
    });

    // Promise가 resolve된 후에 다음 작업이 진행된다
    console.log("## ASSIST_ID after update (async):", assistantIdRef.current);
    console.log("## THREAD_ID after update (async):", threadIdRef.current);
  };

  useEffect(() => {
    changeAssistantAndThreadId('draw_base_saju', '');
    console.log('first update:', threadId, assistantId);
  }, []);

  useEffect(() => {
    console.log('threadId Updated:', threadId, assistantId);
  }, [threadId]);

  function UserInputProcessor({ previousStep, triggerNextStep }) {
    const [response, setResponse] = useState('입력중');
    const [loadingDots, setLoadingDots] = useState('.');
    const [isLoading, setIsLoading] = useState(true);
    const [delayMessage, setDelayMessage] = useState(null);
    const [currentOutputFormat, setCurrentOutputFormat] = useState(''); // New state for output_format

    const [displayStringList] = useState([
      '사주풀이를 보는 중 👀',
      '격을 계산하는 중 🧮',
      '운세를 분석하는 중 🔮',
      '데이터를 수집하는 중 📊',
      '결과를 정리하는 중 📋',
      '별자리를 확인하는 중 🌌',
      '운명의 실타래를 푸는 중 🧵',
      '미래를 예측하는 중 🔮',
      '행운을 찾는 중 🍀',
      '별의 움직임을 추적하는 중 🌠',
      '운세를 해석하는 중 📜',
      '시간의 흐름을 읽는 중 ⏳',
      '운명의 길을 찾는 중 🛤️',
      '별빛을 분석하는 중 ✨',
      '운세의 비밀을 밝히는 중 🔍',
      '행운의 기운을 모으는 중 🌈',
      '미래의 가능성을 탐색하는 중 🚀',
      '운명의 지도를 그리는 중 🗺️',
      '별의 메시지를 해독하는 중 📡',
      '운세의 흐름을 파악하는 중 🌊',
    ]);
    const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0);

    // Function to get the latest parameters
    const getLatestParams = () => ({
      action: additionalParamsRef.current.action,
      output_format: additionalParamsRef.current.output_format,
      api_topic_id_key: additionalParamsRef.current.api_topic_id_key,
      remaining_questions: additionalParamsRef.current.remaining_questions,

      assistantId: assistantIdRef.current,
      threadId: threadIdRef.current,
      birthday: birthdayRef.current,
    });


    const getLatestAssistAndThreadId = () => ({
      assistantId: assistantIdRef.current,
      threadId: threadIdRef.current
    });
  
    useEffect(() => {
      scrollToBottom();
      let loadingInterval;
      let delayCheck;
      let displayInterval;
  
      const fetchData = async () => {
        loadingInterval = setInterval(() => {
          if (isLoading) {
            setLoadingDots((prev) => (prev === '...' ? '.' : prev + '.'));
          }
        }, 700);
  
        delayCheck = setTimeout(() => {
          if (isLoading) {
            setDelayMessage('조금만 기다려줘🙏');
          }
        }, 16000);
  
        const { action, output_format, api_topic_id_key, remaining_questions, assistantId, threadId, birthday } = getLatestParams();
        setCurrentOutputFormat(output_format); 

        let body_params = JSON.stringify({
          message: previousStep.value,
          thread_id: threadId,
          assistant_id_key: assistantId,
        });

          
        if (action === 'saju_api_mixer' && output_format ==='display') {
          // Start cycling through displayStringList
          displayInterval = setInterval(() => {
            setCurrentDisplayIndex((prevIndex) => (prevIndex + 1) % displayStringList.length);
          }, 1000);
        }
  
        if (action === 'saju_api_mixer') {

          console.log('################')
          console.log('ACTION:', action)
          console.log('OUTPUT_FORMAT:', output_format)
          console.log('API_TOPIC_ID_KEY:', api_topic_id_key)
          console.log('ASSIST_ID:', assistantId)
          console.log('THREAD_ID:', threadId)
          console.log('################')
  
          body_params = JSON.stringify({
            message: previousStep.value,
            thread_id: threadId,
            assistant_id_key:assistantId,
            time_info: birthday,
            api_topic_id_key: api_topic_id_key,
            output_format: output_format,
          });
        }
  
        try {

          const response = await fetch('https://www.tagmeet.io/saju/api/chatbot/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
            },
            body: body_params,
          });

          const data = await response.json();
  
          if ('draw-base-saju' === data.trigger) {
            setBirthday(data.saju_data.time_info);
          }
  
          if ('thread_id' in data) {
            handleThreadIdChange(data.thread_id);
          }
  
          clearInterval(loadingInterval);
          clearTimeout(delayCheck);
          clearInterval(displayInterval);
          setIsLoading(false);
  
          let triggerData = {
            trigger: data.trigger,
            value: {
              baseSaju: data.saju_data || {},
            },
          };
  
          //trigger에 따른 output_format 변경(display or answer)
          if (data.trigger.endsWith('-display')) {

            await updateAdditionalParams({
              current_display: data.trigger.replace('-display', ''),
              remaining_questions: 3,
              output_format: 'display',
            }, 'UIP-endswith-display');
            setResponse(data.message);

          }else if (data.trigger.endsWith('-answer')) {

            await updateAdditionalParams({
              output_format: 'answer',
              remaining_questions: remaining_questions - 1,
            }, 'UIP-endswith-answer');
  
            const messageParts = data.message.split('.').filter(part => part.trim() !== '');
            const firstMessage = messageParts[0];
            const remainingMessages = messageParts.slice(1);

            setResponse(firstMessage);
  
            let target_next_trigger = 'user-input';
  
            if ( remaining_questions <= 1) {
              target_next_trigger = 'check-next-action';
              remainingMessages.push(`남은 질문 횟수: 0 `);
            } else {
              remainingMessages.push("궁금한거 있으면 물어봐 ㅎㅎ");
              remainingMessages.push("다음으로 넘어가려면 '다음'이라고 말해줘");
              remainingMessages.push(`남은 질문 횟수: ${remaining_questions - 1}`);
            }
  
            triggerData = {
              trigger: 'make-system-message',
              value: {
                message: remainingMessages,
                next_trigger: target_next_trigger,
              },
            };

          } else { //draw-base-saju
            setResponse(data.message);
          }
  
          triggerNextStep(triggerData);
  
        } catch (error) {
          clearInterval(loadingInterval);
          clearTimeout(delayCheck);
          clearInterval(displayInterval);
          setIsLoading(false);
          setResponse('Failed to get response. Try again later.');
          triggerNextStep({ trigger: 'error' });
        }
      };
  
      const userInput = typeof previousStep.value === 'string' ? previousStep.value : '';
      if ('다음' === userInput.toLowerCase()) {
        setIsLoading(false);
        setResponse('알겠어👌');
        setTimeout(() => {
          triggerNextStep({ trigger: 'check-next-action' });
        }, 0);
      } else {
        fetchData();
      }
  
      return () => {
        clearInterval(loadingInterval);
        clearTimeout(delayCheck);
        clearInterval(displayInterval);
      };
    }, [previousStep, triggerNextStep]);
  
    const displayResponse = isLoading
    ? delayMessage
      ? `${delayMessage}${loadingDots}`
      : (currentOutputFormat === 'display'
        ? displayStringList[currentDisplayIndex]
        : `입력중${loadingDots}`)
    : response;
  
  return displayResponse;
  }
  // function UserInputProcessor({ previousStep, triggerNextStep }) {
  //   //TODO: 입력중의 경우 display가 들어올때 n seconds단위로 실시간 변경해주도록 구현해주자
  //   const [response, setResponse] = useState('입력중');
  //   const [loadingDots, setLoadingDots] = useState('.');
  //   const [isLoading, setIsLoading] = useState(true);
  //   const [delayMessage, setDelayMessage] = useState(null);

  //   useEffect(() => {

  //     scrollToBottom();
  //     // 상위 범위에서 loadingInterval과 delayCheck를 선언하여 모든 함수에서 접근 가능하게 함
  //     let loadingInterval;
  //     let delayCheck;
    
  //     const fetchData = async () => {

  //       loadingInterval = setInterval(() => {
  //         if (isLoading) {
  //           setLoadingDots((prev) => (prev === '...' ? '.' : prev + '.'));
  //         }
  //       }, 700);
    
  //       delayCheck = setTimeout(() => {
  //         if (isLoading) {
  //           setDelayMessage('조금만 기다려줘🙏');
  //         }
  //       }, 15000);
    
  //       // 경우에 따라서 사용하는 GPT 어시스턴트를 스위치 해줄수 있어야 한다.
  //       // 현재 사용하고 있는 어시스턴트 및 스레드아이디를 체크한다(새로 스레드를 파는것 방지 - 이전대화 기억)
  //       // Use refs to access the latest threadId and assistantId
  //       let currentThreadId = threadIdRef.current;
  //       let currentAssistantId = assistantIdRef.current;
  //       let currentBirthday = birthdayRef.current;
        
  //       console.log("########################################")
  //       console.log("UIP - PevStepValue:", previousStep.value);
  //       console.log("########################################")

  //       let body_params = JSON.stringify({ 
  //         message: previousStep.value, 
  //         thread_id: currentThreadId, 
  //         assistant_id_key: currentAssistantId
  //       });

  //       if (additionalParamsRef.current.action === 'saju_api_mixer') {
  //         if (additionalParamsRef.current.output_format === 'display') {
  //           await changeAssistantAndThreadId('saju_api_mixer', '');
  //         }
  //         // else ==> threadIdRef 값 유지

  //         const waitForStateUpdate = () => new Promise(resolve => {
  //           const checkState = () => {
  //             if (assistantIdRef.current === 'saju_api_mixer') {
  //               resolve();
  //             } else {
  //               setTimeout(checkState, 50);
  //             }
  //           };
  //           checkState();
  //         });
          
  //         await waitForStateUpdate();
  //         body_params = JSON.stringify({ 
  //           message: previousStep.value,
  //           thread_id: threadIdRef.current, 
  //           assistant_id_key: assistantIdRef.current,
  //           time_info: birthdayRef.current,
  //           api_topic_id_key: additionalParamsRef.current.api_topic_id_key,
  //           output_format: additionalParamsRef.current.output_format
  //         });
  //       } else { // initial-step
  //         body_params = JSON.stringify({ 
  //           message: previousStep.value, 
  //           thread_id: threadIdRef.current, 
  //           assistant_id_key: assistantIdRef.current
  //         });
  //       }

  //       console.log("AsstantId:", assistantIdRef.current)
  //       console.log("ThreadId:", threadIdRef.current)
  //       console.log("Body_params:", body_params)

  //       try {
  //         const response = await fetch('https://www.tagmeet.io/saju/api/chatbot/', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //             'X-CSRFToken': csrfToken,
  //           },
  //           body: body_params,
  //         });
  //         const data = await response.json();
          
          
  //         if ('draw-base-saju' === data.trigger) {
  //           setBirthday(data.saju_data.time_info);
  //         }
    
  //         // Handle thread ID update
  //         if ('thread_id' in data) {
  //           handleThreadIdChange(data.thread_id); // This will update the state correctly
  //         }

  //         clearInterval(loadingInterval);
  //         clearTimeout(delayCheck);
  //         setIsLoading(false);

  //         console.log("DATA.TRIGGER", data.trigger)
  //         console.log("DATA.SAJU_DATA", data.saju_data)

  //         let triggerData = {
  //           trigger: data.trigger,
  //           value: {
  //             baseSaju: data.saju_data || {},
  //           },
  //         };

  //         if (data.trigger.endsWith('-display')) {

  //           await updateAdditionalParams({
  //             current_display: data.trigger.replace('-display', ''),
  //             remaining_questions: 3,
  //             output_format: 'display'
  //           }, 'UIP-endswith-display');
  //           setResponse(data.message);
  //         } 
  //         // Handle answer triggers
  //         // -answer나온 것 자체가 이미 한번 사용한것임
  //         else if (data.trigger.endsWith('-answer')) {

  //           await updateAdditionalParams({
  //             output_format: 'answer',
  //             remaining_questions: additionalParamsRef.current.remaining_questions - 1
  //           }, 'UIP-endswith-answer');
  
  //           const messageParts = data.message.split('.').filter(part => part.trim() !== '');
  //           const firstMessage = messageParts[0];
  //           const remainingMessages = messageParts.slice(1);
  //           setResponse(firstMessage);
            
  //           let target_next_trigger = 'user-input'

  //           if (additionalParamsRef.current.remaining_questions <= 0) {
  //             target_next_trigger = 'check-next-action'
  //             remainingMessages.push(`남은 질문 횟수: ${additionalParamsRef.current.remaining_questions}`);

  //           } else {
  //             remainingMessages.push("궁금한거 있으면 물어봐 ㅎㅎ");
  //             remainingMessages.push("다음으로 넘어가려면 '다음'이라고 말해줘");
  //             remainingMessages.push(`남은 질문 횟수: ${additionalParamsRef.current.remaining_questions}`);
  //           }
  
  //           triggerData = {
  //             trigger: 'make-system-message',
  //             value: {
  //               message: remainingMessages,
  //               next_trigger: target_next_trigger,
  //             }
  //           };

  //         }else{
  //           setResponse(data.message);
  //         }

  //         triggerNextStep(triggerData);

  //       } catch (error) {
  //         clearInterval(loadingInterval);
  //         clearTimeout(delayCheck);
  //         setIsLoading(false);
  //         setResponse('Failed to get response. Try again later.');
  //         triggerNextStep({ trigger: 'error' });
  //       }
  //     };
    
  //     const userInput = typeof previousStep.value === 'string' ? previousStep.value : '';
  //     if ('다음' === userInput.toLowerCase()) {
  //       setIsLoading(false);
  //       setResponse('알겠어👌')
  //       setTimeout(() => {
  //         triggerNextStep({ trigger: 'check-next-action' });
  //       }, 0);
  //     }else{
  //       fetchData();  
  //     }
    
  //     return () => {
  //       clearInterval(loadingInterval);
  //       clearTimeout(delayCheck);
  //     };
  //   }, [previousStep, triggerNextStep]);


  //   const displayResponse = isLoading
  //     ? delayMessage
  //       ? `${delayMessage}${loadingDots}`
  //       : `입력중${loadingDots}`
  //     : response;

  //   return displayResponse;
  // }



  // TODO: 질문을 얼마나 많이 하는지 카운트 할 수 있어야함
  // life_challenge-answer 일때
  function LifeChallengeDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;


    // #TODO: previous
    // const triggerData = {
    //   trigger: 'make-system-message',
    //   value: {
    //     message: [
    //       "첫번째 내용은 너의 전체적인 성향에 관한 정보야", "사람은 모두가 장단점이 있기 때문에", "자신의 장점은 활용하고, 단점은 고치고 미리 파악해서 피해가면 좋겠지?",
    //       "내용을 봐보고 궁금한게 있으면 자유롭게 물어봐", "만약 질문이 없다면 '다음' 이라고 정확하게 입력해줘"
    //     ],
    //     next_trigger: 'user-input',
    //   }
    // };


    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "첫번째 내용은 너의 전체적인 성향에 관한 정보야", "사람은 모두가 장단점이 있기 때문에", "자신의 장점은 활용하고, 단점은 고치고 미리 파악해서 피해가면 좋겠지?",
          "내용을 봐보고 궁금한게 있으면 자유롭게 물어봐", "보기에서 질문을 골라도 되고 직접 물어봐도 좋아", "질문이 없다면 다음 버튼을 눌러줘"
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'life-challenge-questions',
        }
      }
    };

    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: 'life_challenge',
          current_display: 'life_challenge',
          remaining_questions: 3  // 질문 횟수 초기화
        }, 'life-challenge-display-component');
  
        scrollToBottom();
  
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
  
      updateParams();
      
    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div className="base-saju-info">
        <h2 className="title">나를 돌아보는 시간</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">한 줄 요약</h3>
              <p>{component.one_line_summary}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">핵심 키워드</h3>
              <div className="keyword-container">
                {renderKeywords(component.keywords)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">특징적인 성향</h3>
              <div className="bullet-points-container">
                {renderBulletPoints(component.traits)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">상세 설명</h3>
              <p>{component.contents}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }


  function MoneyLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "재물운에 대해 더 궁금한 점이 있으면 자유롭게 물어보고", "질문이 없다면 '다음' 이라고 정확하게 입력해줘"
        ],
        next_trigger: 'user-input',
      }
    };
  
    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: 'money_luck',
          current_display: 'money_luck',
          remaining_questions: 3  // 질문 횟수 초기화
        }, 'money-luck-display');
    
        scrollToBottom();
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
    
      updateParams();
    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };

    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div className="base-saju-info">
        <h2 className="title">재물운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">재물운 요약</h3>
              <p>{component.financial_summary}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">재물 키워드</h3>
              <div className="keyword-container">
                {renderKeywords(component.money_keywords)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">재물 관련 특성</h3>
              <div className="bullet-points-container">
                {renderBulletPoints(component.financial_traits)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">수입 잠재력</h3>
              <p>{component.income_potential}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">소비 습관</h3>
              <p>{component.spending_habits}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">투자 조언</h3>
              <p>{component.investment_advice}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">재정적 도전</h3>
              <p>{component.financial_challenges}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">장기 전망</h3>
              <p>{component.long_term_outlook}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }


  function LoveLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "애정운에 대해 더 궁금한 점이 있으면 물어봐~", "질문이 없다면 '다음' 이라고 정확하게 입력해줘"
        ],
        next_trigger: 'user-input',
      }
    };

    useEffect(() => {

      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: 'love_luck',
          current_display: 'love_luck',
          remaining_questions: 3  // 질문 횟수 초기화
        }, 'love-luck-display');
    
        scrollToBottom();

        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };

      updateParams();

    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div className="base-saju-info">
        <h2 className="title">애정운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">애정운 요약</h3>
              <p>{component.love_summary}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">애정 키워드</h3>
              <div className="keyword-container">
                {renderKeywords(component.love_keywords)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">연애 관련 특성</h3>
              <div className="bullet-points-container">
                {renderBulletPoints(component.romantic_traits)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">이상형</h3>
              <p>{component.ideal_partner}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">연애 스타일</h3>
              <p>{component.love_style}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">연애에서의 도전</h3>
              <p>{component.relationship_challenges}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">상대방과의 궁합</h3>
              <p>{component.compatibility}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">미래 전망</h3>
              <p>{component.future_prospects}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function CareerLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "직장/명예운에 대해 더 궁금한 점이 있으면 물어봐 ㅎ", "질문이 없다면 '다음' 이라고 정확하게 입력해줘"
        ],
        next_trigger: 'user-input',
      }
    };
  
  
  useEffect(() => {
    const updateParams = async () => {
      await updateAdditionalParams({
        action: 'saju_api_mixer',
        output_format: 'answer',
        api_topic_id_key: 'career_luck',
        current_display: 'career_luck',
        remaining_questions: 3  // 질문 횟수 초기화
      }, 'career-luck-display');

      scrollToBottom();
      const timer = setTimeout(() => {
        triggerNextStep(triggerData);
      }, 10);
      return () => clearTimeout(timer);
    };

    updateParams();
  }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div className="base-saju-info">
        <h2 className="title">직장/명예운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">경력 요약</h3>
              <p>{component.career_summary}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">경력 키워드</h3>
              <div className="keyword-container">
                {renderKeywords(component.career_keywords)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">직업적 특성</h3>
              <div className="bullet-points-container">
                {renderBulletPoints(component.professional_traits)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">소통 방식</h3>
              <p>{component.communication_style}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">경력 잠재력</h3>
              <p>{component.career_potential}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">리더십 특성</h3>
              <p>{component.leadership_qualities}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">직장에서의 도전</h3>
              <p>{component.work_challenges}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">평판 구축</h3>
              <p>{component.reputation_building}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">미래 경력 전망</h3>
              <p>{component.future_career_prospects}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }


  function StudyLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "학업/계약운에 대해 더 궁금한 점이 있으면 물어봐", "질문이 없다면 '다음' 이라고 정확하게 입력해줘"
        ],
        next_trigger: 'user-input',
      }
    };
  
    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: 'study_luck',
          current_display: 'study_luck',
          remaining_questions: 3  // 질문 횟수 초기화
        }, 'study-luck-display');
    
        scrollToBottom();
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
    
      updateParams();

    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div className="base-saju-info">
        <h2 className="title">학업/계약운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">전체 요약</h3>
              <p>{component.overall_summary}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">주요 특성</h3>
              <div className="keyword-container">
                {renderKeywords(component.key_aspects)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">학습 특성</h3>
              <div className="bullet-points-container">
                {renderBulletPoints(component.learning_traits)}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">적성 분석</h3>
              <p>{component.aptitude_analysis}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">학습 전략</h3>
              <p>{component.study_strategy}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">계약 협상</h3>
              <p>{component.contract_negotiation}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">도전과 해결책</h3>
              <p>{component.challenges_and_solutions}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">자신감 관리</h3>
              <p>{component.confidence_management}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">인내심 조언</h3>
              <p>{component.perseverance_advice}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">미래 전망</h3>
              <p>{component.future_prospects}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  


 //유료 사주 운세 4,5,6 - 재물, 연애
 function GetMoreBaseSaju({ steps, previousStep, triggerNextStep }) {
  const {
    time_info,
    income_potential,
    investment_potential,
    expenditure_habit,
    dating_style,
    ideal_partner,
    breakup_reason,
  } = previousStep.value.baseSaju;

  const triggerData = {
    trigger: 'terminate-service',
  };

  useEffect(() => {
    triggerNextStep(triggerData); // Move to the next step
  }, [triggerNextStep]);

  // Function to format text by replacing newline characters and slashes
  const formatText = (text) => {
    return text
      .replace(/\\n/g, '<br><br>')  // Replace \n with <br/> for new line
      .replace(/\//g, '<br><br>');   // Replace / with <br/> for new line
  };

  // Function to render bullet points from text with dots or new lines
  const renderBulletPoints = (text) => {
    return text.split(/・|\\n|\/|\./g).map((line, index) => (
      line.trim() && <li key={index}>{line.trim()}</li>
    ));
  };

  const renderListItems = (text) => {
    return text.split(/\\n|・/g).map((item, index) => (
      <li key={index}>{item.trim()}</li>
    ));
  };

  const renderDatingStyleSubData = (category, subData) => {
    if (subData && subData.contents) {
      return (
        <div className="info-item" key={category}>
          <strong>{category}:</strong>
          <p>{subData.contents}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="base-saju-info">
      {/* 소득 가능성 카드 */}
      <div className="card">
        <h3 className="card-title">소득 가능성</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>소득 경쟁력:</strong>
            <ul>{renderBulletPoints(income_potential.contents.level.contents)}</ul>
          </div>
          <div className="info-item">
            <strong>소득 기회와 타이밍:</strong>
            <ul>{renderBulletPoints(income_potential.contents.will.contents)}</ul>
          </div>
        </div>
      </div>

      {/* 투자 가능성 카드 */}
      <div className="card">
        <h3 className="card-title">투자 가능성</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>투자 성향:</strong>
            <p>{investment_potential.type}</p>
          </div>
          <div className="info-item">
            <strong>투자 관련 주요 특징:</strong>
            <ul>{renderBulletPoints(investment_potential.keyword)}</ul>
          </div>
        </div>
      </div>

      {/* 소비 습관 카드 */}
      <div className="card">
        <h3 className="card-title">소비 습관</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>소비 스타일:</strong>
            <p>{expenditure_habit.title}</p>
          </div>
          <div className="info-item">
            <strong>지출 경향:</strong>
            <ul>{renderBulletPoints(expenditure_habit.contents)}</ul>
          </div>
        </div>
      </div>

      {/* 연애 및 결혼 성향 카드 */}
      <div className="card">
        <h3 className="card-title">연애 및 결혼 성향</h3>
        <div className="card-content">
          {renderDatingStyleSubData('독립적 성향', dating_style.data.dokrip)}
          {renderDatingStyleSubData('비독립 성향', dating_style.data.bedokrip)}
          {renderDatingStyleSubData('근왕 성향', dating_style.data.geunwang)}
          {renderDatingStyleSubData('인성 성향', dating_style.data.insung)}
          {renderDatingStyleSubData('삼합 성향', dating_style.data.samhap)}
          {renderDatingStyleSubData('토간 성향', dating_style.data.togan)}
        </div>
      </div>

      {/* 만나면 좋은 이성 카드 */}
      <div className="card">
        <h3 className="card-title">이상적인 이성</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>이성의 특징:</strong>
            <p>{ideal_partner.title}</p>
          </div>
          <div className="info-item">
            <strong>상세 설명:</strong>
            <ul>{renderBulletPoints(ideal_partner.contents)}</ul>
          </div>
        </div>
      </div>

      {/* 이별의 이유 카드 */}
      <div className="card">
        <h3 className="card-title">이별의 이유</h3>
        <div className="card-content">
          <div className="info-item">
            <strong>이별을 부르는 행동:</strong>
            <p>{breakup_reason.title}</p>
          </div>
          <div className="info-item">
            <strong>세부 내용:</strong>
            <ul>{renderBulletPoints(breakup_reason.contents)}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}
    


  //무료 사주 운세 1,2,3 - 성격, 재능, 충합관련 내용
  function BaseSajuInfo({ steps, previousStep, triggerNextStep }) {
    const { saju_pillar, umyangohang, time_info, personality, talent, talent_type } = previousStep.value.baseSaju;

    let which_character = '';
    let anger_trigger = '';

    if (personality && personality.data.contents) {
      const parts = personality.data.contents.split('/');
      which_character = parts[0] || '';  // First item after split
      anger_trigger = parts[1] || '';    // Second item after split
    }

    const [isTriggered, setIsTriggered] = useState(false);

     // useCallback을 사용하여 triggerNextStep 호출 최적화
    const handleTriggerNextStep = useCallback(() => {
      if (!isTriggered) {
        triggerNextStep({
          trigger: 'make-system-message',
          value: {
            time_info: time_info,
            message: ["재물운 그리고 연애운도 궁금하지??"],
            next_trigger: 'display-yes-or-no'
          }
        });
        setIsTriggered(true);
      }
    }, [isTriggered, time_info, triggerNextStep]);

    useEffect(() => {
      handleTriggerNextStep();
    }, [handleTriggerNextStep]);
  
    // Function to format text by replacing newline characters and slashes
    const formatText = (text) => {
      return text
        .replace(/\\n/g, '<br><br>')  // Replace \n with <br/> for new line
        .replace(/\//g, '<br><br>');   // Replace / with <br/> for new line
    };
  
    const renderKeywords = (keywords) => {
      return keywords
        .replace(/\//g, '') // Remove slashes for keyword tags
        .split('#')          // Split by '#'
        .filter(Boolean)     // Remove empty strings
        .map((keyword, index) => (
          <span key={index} className="keyword-badge">
            #{keyword.trim()}
          </span>
        ));
    };

    const renderBulletPoints = (text) => {
      return text.split(/\/|\\n/g).map((line, index) => (
        line.trim() && <li key={index}>{line.trim()}</li>
      ));
    };
  
    return (
      <div className="base-saju-info">
        <h2 className="title">사주 풀이</h2>
  
        {/* Personality Section */}
        {personality && (
          <div className="card">
            <h3 className="card-title">타고난 성격</h3>
            <div className="card-content">
              <div className="info-item">
                <strong>일간:</strong>
                <p>{personality.data.ilgan}</p>
              </div>
              <div className="info-item">
                <strong>한마디로 하면:</strong>
                <p>{personality.data.ilganDesciption}</p>
              </div>
              <div className="info-item">
                <strong>키워드:</strong>
                {renderKeywords(personality.data.subtitle)}
              </div>
              <div className="info-item">
                <strong>어떤사람?:</strong>
                <ul>
                  {renderBulletPoints(which_character)}
                </ul>
              </div>
              <div className="info-item">
                <strong>화나게 하는 것:</strong>
                <ul>
                  {renderBulletPoints(anger_trigger)}
                </ul>
              </div>
            </div>
          </div>
        )}
  
        {/* Talent Section */}
        {talent && (
          <div className="card">
            <h3 className="card-title">타고난 재능</h3>
            <div className="card-content">
              <div className="info-item">
                <strong>용신:</strong>
                <p>{talent.youngsin}</p>
              </div>
              <div className="info-item">
                <strong>한마디로 하면:</strong>
                <p>{talent.data.subtitle}</p>
              </div>
              <div className="info-item">
                <strong>키워드:</strong>
                {renderKeywords(talent.data.tag)}
              </div>
              <div className="info-item">
                <strong>어떤재능?:</strong>
                <p dangerouslySetInnerHTML={{ __html: formatText(talent.data.contents) }} />
              </div>
            </div>
          </div>
        )}
  
        {/* Talent Type Section */}
        {talent_type && (
          <div className="card">
            <h3 className="card-title">인재 유형</h3>
            <div className="card-content">
              <div className="info-item">
                <strong>분야:</strong>
                <p>{talent_type.title}</p>
              </div>
              <div className="info-item">
                <strong>인재유형:</strong>
                <p>{talent_type.type}</p>
              </div>
              <div className="info-item">
                <strong>설명하자면?:</strong>
                <p dangerouslySetInnerHTML={{ __html: formatText(talent_type.contents) }} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  
  // 사주팔자 기본 8글자
  function DrawBaseSaju({ steps, previousStep, triggerNextStep }) {
    // Accessing the data passed from the previous step
    // const baseSaju = previousStep.value.baseSaju;
    // TODO: 아래 message로 표시할 당신은 OO 일간이네요
    // 좋아요! ㅇㅇㅇ님은 [봄/여름/가을/겨울]에 태어난 [갑목일간/을목일간/병화일간/정화일간/무토일간/기토일간/경금일간/신금일간/임수일간/계수일간]이군요. 큰 틀에서 보면 [~~~~~~]하고, [~~~~~]한 분이라고 볼 수 있어요 😊
    const { saju_pillar, umyangohang} = previousStep.value.baseSaju;
    const [hasTriggered, setHasTriggered] = useState(false); // 중복 방지 상태

    //단계 : draw-base-saju (그림 사주팔자) ---> base-saju-info (기본 사주팔자 내용) 단 무료내용인 재능/적성까지.
    //value required : target_ui_component, time_info, message 
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "오...?",
          "흥미로운 사주팔자인데?!",
          "사주팔자는 생년월일시를 기반으로 하는 4개의 기둥을 구하고 각 기둥에 2글자씩 계산해서 8자가 나오는 개념이야",
          "4개의 기둥 8개의 글자라 해서 4주8자라해.",
          "참고로 일간은 '나의 성향'에 관련된 글자고",
          "월지는 내가 상호작용하는 '환경과 사회'와 관련된 글자라고 보면 돼",
          "사주풀이는 다음 순서대로 보여줄게 ㅎㅎ",
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'fortune-list',
        }
      }
    };
    
    useEffect(() => {
      if (!hasTriggered) {
        scrollToBottom(); // Ensure the chat window is scrolled to the bottom
        triggerNextStep(triggerData); // Move to the next step
        setHasTriggered(true); // 한 번만 실행되도록 설정
      }
    }, [hasTriggered, triggerNextStep]);

    return (
      <div className="saju-container">
      <h2 className="saju-title">사주팔자</h2>
      <div className="saju-pillar-grid">
        {/* Headers */}
        {/* <div className="pillar-header">시주</div>
        <div className="pillar-header">일주</div>
        <div className="pillar-header">월주</div>
        <div className="pillar-header">년주</div> */}

        {/* 천간 and 지지 Row */}
        <OhangBox 
          sky={umyangohang.timeSky.ohang} 
          land={umyangohang.timeLand.ohang} 
          pillar={{ sky: saju_pillar.timeSky, land: saju_pillar.timeLand }} 
          headerSky="시간"
          headerLand="시지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText=" 󠀠"
        />
        <OhangBox 
          sky={umyangohang.daySky.ohang} 
          land={umyangohang.dayLand.ohang} 
          pillar={{ sky: saju_pillar.daySky, land: saju_pillar.dayLand }} 
          headerSky="일간"
          headerLand="일지"
          headerSkyExtraText="나"
          headerLandExtraText=" 󠀠"
        />
        <OhangBox 
          sky={umyangohang.monthSky.ohang} 
          land={umyangohang.monthLand.ohang} 
          pillar={{ sky: saju_pillar.monthSky, land: saju_pillar.monthLand }} 
          headerSky="월간"
          headerLand="월지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText="사회"
        />
        <OhangBox 
          sky={umyangohang.yearSky.ohang} 
          land={umyangohang.yearLand.ohang} 
          pillar={{ sky: saju_pillar.yearSky, land: saju_pillar.yearLand }} 
          headerSky="년간"
          headerLand="년지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText=" 󠀠"
        />
      </div>
    </div>
    );
  }

  // 새로운 함수: 다음 display로 이동
  // TODO: message전달문제도 같이 확인 필요함
 function MoveToNextDisplay({ steps, previousStep, triggerNextStep }) {
  const [message, setMessage] = useState("");

  useEffect(() => {

    
    scrollToBottom();

    const moveToNext = async () => {
      console.log("moveToNextDisplay 함수 실행");
      console.log("현재 additionalParamsRef:", additionalParamsRef.current);
    
      const currentIndex = additionalParamsRef.current.display_order.indexOf(additionalParamsRef.current.current_display);
      console.log("현재 display 인덱스:", currentIndex);
    
      const nextDisplay = additionalParamsRef.current.display_order[currentIndex + 1];
      console.log("다음 display:", nextDisplay);

      const nextDisplayString = additionalParamsRef.current.display_order_kor_str[currentIndex + 1];
    
      let nextTrigger;
    
      if (nextDisplay) {
        console.log("다음 display로 이동");

        await changeAssistantAndThreadId('saju_api_mixer', '');
        // 기존의 remaining_questions 확인
        const currentRemainingQuestions = additionalParamsRef.current.remaining_questions;
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'display',
          api_topic_id_key: nextDisplay,
          current_display: nextDisplay,
          remaining_questions: 3  // 질문 횟수 초기화
        }, 'move-next-display');

         // 파라미터 업데이트를 기다리는 함수
         const waitForParamUpdate = () => new Promise(resolve => {
          const checkParams = () => {
            if (additionalParamsRef.current.output_format === 'display' &&
                additionalParamsRef.current.current_display === nextDisplay) {
              resolve();
            } else {
              setTimeout(checkParams, 50);
            }
          };
          checkParams();
        });
        
        await waitForParamUpdate();
  
        console.log("업데이트된 additionalParamsRef:", additionalParamsRef.current);
        
        // remaining_questions에 따라 메시지 설정
        if (currentRemainingQuestions <= 0) {
          setMessage(`질문권을 다 사용했네. 이번엔 ${nextDisplayString}에 대해서 살펴보자`);
        } else {
          setMessage(`그럼 이번엔 ${nextDisplayString}에 대해서 살펴보자`);
        }
        
        nextTrigger = 'user-input-processor';
      } else {
        console.log("모든 display를 완료했습니다. 서비스를 종료합니다.");
        setMessage("모든 운세 분석 완료!");
        nextTrigger = 'terminate-service';
      }
    
      // 다음 단계로 이동
      triggerNextStep({ 
        trigger: nextTrigger,
        value: {
          message: 'hello'
        }
      });
      
    };

    moveToNext();
  }, []); // 빈 의존성 배열 사용

  return message;
}
  // required: value.message, value.next_trigger
  function MakeSystemMessage({ steps, previousStep, triggerNextStep }) {
    const messages = Array.isArray(previousStep.value.message) 
      ? previousStep.value.message 
      : [previousStep.value.message];
    const next_trigger = previousStep.value.next_trigger;
    const additional_params = previousStep.value.additional_params || {};
  
    useEffect(() => {

      scrollToBottom();

      const timer = setTimeout(() => {
        if (messages.length > 1) {
          // 아직 표시할 메시지가 더 있는 경우
          triggerNextStep({
            trigger: 'make-system-message',
            value: {
              message: messages.slice(1),
              next_trigger: next_trigger,
              additional_params: additional_params
            }
          });
        } else {
          // 마지막 메시지인 경우
          triggerNextStep({
              trigger: next_trigger,
              value: {
                ...additional_params,
                message: messages[0]
              }
          });

        }
        scrollToBottom();
      }, 1200);
      return () => clearTimeout(timer);
    }, []);
  
    return messages[0];
  }

  //value required : target_ui_component, message 
  function DisplayInteractiveComponent({ steps, previousStep, triggerNextStep }) {
    const { target_ui_component, message } = previousStep.value.additional_params || previousStep.value;
  
    useEffect(() => {
      scrollToBottom();
      const timer = setTimeout(scrollToBottom, 100);
      return () => clearTimeout(timer);
    }, []);
  
    useEffect(() => {
      const updateParams = async () => {
        if (target_ui_component === 'custom-option-1') {

          await changeAssistantAndThreadId('saju_api_mixer', '');

          await updateAdditionalParams({
            action: 'saju_api_mixer',
            output_format: 'display',
            api_topic_id_key: 'life_challenge',
          }, 'display-interactive-component');

        }
      };
  
      updateParams();
    }, [target_ui_component]);
  
    const renderComponentByType = () => {
      switch (target_ui_component) {
        case 'fortune-list':
          const triggerData = {
            trigger: 'make-system-message',
            value: {
              message: ['위에 보여진 순서대로 사주풀이를 봐줄게', '각 섹션에서 최대 3번 질문할 수 있어', '준비됐지?'],
              next_trigger: 'display-interactive-component',
              additional_params: {
                target_ui_component: 'custom-option-1',
              }
            }
          };
          return <FortuneList triggerNextStep={triggerNextStep} triggerData={triggerData} />;
        
        case 'custom-option-1':
          const customOptionTriggerData = {
            yesOption: {
              trigger: 'make-system-message',
              value: {
                message: ['좋아👍그럼 먼저 전체적인 성향부터 살펴볼게', '시간이 조금 걸릴수도 있어'],
                next_trigger: 'user-input-processor',
              }
            }
          };
          return <CustomOptionButtons triggerNextStep={triggerNextStep} triggerData={customOptionTriggerData} />;

        case 'life-challenge-questions':

            const sampleTriggerData = {
              yesOption: {
                trigger: 'make-system-message',
                value: {
                  message: ['좋아👍그럼 먼저 전체적인 성향부터 살펴볼게', '시간이 조금 걸릴수도 있어'],
                  next_trigger: 'user-input-processor',
                }
              }
            };
          return <LifeChallengeQuestions triggerNextStep={triggerNextStep} triggerData={sampleTriggerData} />;
  
        default:
          return <p>올바른 컴포넌트를 찾을 수 없습니다.</p>;
      }
    };
  
    return (
      <div className="interactive-container">
        {renderComponentByType()}
      </div>
    );
  }


  // TODL: DEV
  //현재 플로우

  // 무료 플로우
  // start --> 
  // user-input --> user-ipnut-processor - views.py에서 판단하고 넥스트 스텝을 정해서 보여준다.
  // 내용에 따라 draw-base-saju --> base-saju-info
  // base-sajuinfo --> user-input으로 돌아옴

  // 유료 플로우
  //유료 컨텐르를 보시겠습니까? (라는 메시지를 주어야 하기 때문에)
  // base-saju-info가 끝나고 메시지를 어떻게 display 할것인지?

  //챗봇 플로우 노드 구성
  const [steps, setSteps] = useState([
    {
      id: 'start',
      message: '안녕?! 생년월일 그리고 태어난 시간을 오전, 오후 구분하여 입력해줄래?',
      trigger: 'user-input',
    },


    ////////////////////////////////////////////////////////////////////////////////
    // 유저가 입력하면 ----> 유저 인풋 프로세서로 들어가게 된다.
    // UserInputProcessor는 user-input에서 받은 내용을 서버에 보내서 가져온다.
    {
      id: 'user-input',
      user: true,
      trigger: 'user-input-processor'
    },
    // 중개기 노드라고 보면 될듯한데..?
    {
      id: 'user-input-processor',
      component: <UserInputProcessor/>,
      waitAction: true,
      trigger: 'user-input',
      asMessage: true
    },
    {
      id: 'display-yes-or-no',
      options: [
        { 
          label: '응', 
          trigger: 'user-input-processor',
          value: {
            action: 'saju_api_mixer', // 이전 스텝의 time_info를 다음 스텝으로 전달
          }
        },
        { 
          value: "아니", 
          label: '아니', 
          trigger:'terminate-service'
        },
      ],
    },


    ////////////////////////////////////////////////////////////////////////
    {
      id: 'draw-base-saju',
      component: <DrawBaseSaju />, //기본 8개의 사주팔자
      waitAction: true,
      trigger: 'user-input'
    },

    //////총운 관련
    {
      id: 'life-challenge-display',
      component: <LifeChallengeDisplay/>, 
      waitAction: true,
      trigger: 'terminate-service',
    },
    {
      id: 'money-luck-display',
      component: <MoneyLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: 'love-luck-display',
      component: <LoveLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: 'career-luck-display',
      component: <CareerLuckDisplay/>,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: 'study-luck-display',
      component: <StudyLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: 'base-saju-info',
      component: <BaseSajuInfo />, // 적성/ 재능
      waitAction: true,
      trigger: 'user-input',
    },

    // 유료 컨텐츠 
    {
      id: 'get-more-base-saju',
      component: <GetMoreBaseSaju/>, // 재물, 연애
      waitAction: true,
      trigger: 'user-input',
    },

    ////////////////////////////////////////////////////////////////////////
    // 서버에 보내지 않고 컨텐츠 분기점을 만들어주는 노드
    // 경우에 따라서 assistant_id_key 값을 변경해줘야 할수도 있다. 
    {
      id: 'make-system-message',
      component: <MakeSystemMessage />,
      waitAction: true,
      trigger: ({ value }) => value.trigger || 'make-system-message',
      asMessage: true
    },
    {
      id: 'display-interactive-component',
      component: <DisplayInteractiveComponent />,
      waitAction: true,
      trigger: ({ value }) => value.trigger || 'error',
    },
    {
      id: 'check-next-action',
      component :<MoveToNextDisplay/>,
      waitAction: true,
      trigger: ({ value }) => value.trigger || 'error',
      asMessage: true
    },
    {
      id: 'error',
      message: 'There was an error processing your request.',
      end: true,
    }, 
    {
      id: 'terminate-service',
      message: '사주풀이가 완료 되었어 :)',
      end: true,
    }
  ]);

  const botName = "서화도령"

  const theme = {
    background: "#f5f8fb",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#007BFF",
    headerFontColor: "#fff",
    headerFontSize: "15px",

    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
    
    botBubbleColor: "#007BFF",
    botFontColor: "#fff",
  }


  useEffect(() => {
  
    // Adjust scroll after a slight delay to ensure DOM is updated
    const initialScrollTimeout = setTimeout(scrollToBottom, 100);
  
    // Adjust scroll immediately when steps change
    scrollToBottom();
  
    return () => clearTimeout(initialScrollTimeout);
  }, [steps]);

  
  

  return <div className="saju-page">
     <div className="chatbot"> 
      <ThemeProvider theme={theme}>
        <ChatBot
          ref={chatbotRef}
          style={{ height: '100vh' }}
          contentStyle={{ marginTop: '30px', height: 'calc(97vh - 120px)' }}
          customStyle={{
            padding: '0',
            margin: '0',
            marginBottom: '10px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}
          botAvatar={botAvatar}
          hideUserAvatar={true}
          headerTitle={botName}
          steps={steps}
          enableSmoothScroll={true} 
          bubbleStyle={ {textAlign: 'left'} }
          />
      </ThemeProvider>
    </div>
  </div>;

}


const OhangBox = ({ sky, land, pillar, headerSky, headerLand, headerSkyExtraText, headerLandExtraText }) => {
  return (
    <div className="pillar">
      <div className="pillar-section">
        <div className="pillar-header">{headerSky}</div>
        <div
          className="pillar-sky"
          style={{ backgroundColor: ohangData[sky].color }}
        >
          <span className="ohang-extra-text">{headerSkyExtraText}</span>
          <span className="ohang-symbol">{ohangData[sky].symbol}</span>
          <span className="ohang-text">{pillar.sky}</span>
        </div>
      </div>
      <div className="pillar-section">
        <div className="pillar-header">{headerLand}</div>
        <div
          className="pillar-land"
          style={{ backgroundColor: ohangData[land].color }}
        >
          <span className="ohang-extra-text">{headerLandExtraText}</span>
          <span className="ohang-symbol">{ohangData[land].symbol}</span>
          <span className="ohang-text">{pillar.land}</span>
        </div>
      </div>
    </div>
  );
};



export default SajuPage;
